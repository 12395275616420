import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';

export type GetUpcoming = () => void;

export function createGetUpcomingAction({
  context,
  getControllerState,
}: CreateActionParams): GetUpcoming {
  return async () => {
    const { api, fedopsLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.UpcomingTabLoaded);

    const [state, setState] = getControllerState();

    const upcomingData = await api.getEnrichedBookings({
      type: TabTypes.UPCOMING,
      applicationState: state,
    });

    setState({
      upcomingData,
    });

    fedopsLogger?.interactionEnded(EventNames.UpcomingTabLoaded);
  };
}
