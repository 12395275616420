import { CreateActionParams } from '../actions';
import { TabTypes } from '../../types';
import { EventNames } from '../../core/fedops';

export type GetHistory = () => void;

export function createGetHistoryAction({
  context,
  getControllerState,
}: CreateActionParams): GetHistory {
  return async () => {
    const { api, fedopsLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.HistoryTabLoaded);

    const [state, setState] = getControllerState();

    const historyData = await api.getEnrichedBookings({
      type: TabTypes.HISTORY,
      applicationState: state,
    });

    setState({
      historyData,
    });

    fedopsLogger?.interactionEnded(EventNames.HistoryTabLoaded);
  };
}
